/*
  Обозначения:
  _a (all) - применяет свойства к элементу и его дочерним элементам
  _h (hover) - применяет свойства к элементу при получении им фокуса (например, наведение указателя мыши)
  _i (important) - применяет свойства с повышенным приоритетом
*/
* {
  background: scroll none center top no-repeat transparent;
  border: 0 solid transparent;
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  color: #111;
  float: none;
  font-family: "Fira Sans", "Arial", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 150%;
  margin: 0;
  padding: 0;
  outline: none;
  text-align: left;
  text-decoration: none;
  text-transform: none;
  vertical-align: top;
  white-space: normal;
  word-spacing: 0;
  z-index: 0;
}

html, body {
  width: 100%;
}

body {
  text-align: center;
  background-color: #fff;
}

.clearfix:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.lh100 { line-height: 100%;}
.lh110 { line-height: 110%;}
.lh130 { line-height: 130%;}
.lh140 { line-height: 140%;}
.lh150 { line-height: 150%;}
.lh160 { line-height: 160%;}
.lh170 { line-height: 170%;}
.lh180 { line-height: 180%;}
.lh190 { line-height: 190%;}
.lh200 { line-height: 200%;}

.bgc0, .bgc0_h:hover { background-color: #000; }
.bgc0_i, .bgc0_h_i:hover { background-color: #000 !important; }
.bgc0_25p, .bgc0_25p_h:hover { background-color: rgba(0,0,0,.25); }
.bgc0_25p_i, .bgc0_25p_h_i:hover { background-color: rgba(0,0,0,.25) !important; }
.bgc0_50p, .bgc0_50p_h:hover { background-color: rgba(0,0,0,.5); }
.bgc0_50p_i, .bgc0_50p_h_i:hover { background-color: rgba(0,0,0,.5) !important; }
.bgc0_75p, .bgc0_75p_h:hover { background-color: rgba(0,0,0,.75); }
.bgc0_75p_i, .bgc0_75p_h_i:hover { background-color: rgba(0,0,0,.75) !important; }
.bgcw, .bgcw_h:hover { background-color: #fff; }
.bgcw_i, .bgcw_h_i:hover { background-color: #fff !important; }
.bgcw_25p, .bgcw_25p_h:hover { background-color: rgba(255,255,255,.25); }
.bgcw_25p_i, .bgcw_25p_h_i:hover { background-color: rgba(255,255,255,.25) !important; }
.bgcw_50p, .bgcw_50p_h:hover { background-color: rgba(255,255,255,.5); }
.bgcw_50p_i, .bgcw_50p_h_i:hover { background-color: rgba(255,255,255,.5) !important; }
.bgcw_75p, .bgcw_75p_h:hover { background-color: rgba(255,255,255,.75); }
.bgcw_75p_i, .bgcw_75p_h_i:hover { background-color: rgba(255,255,255,.75) !important; }
.bgcf6, .bgcf6_h:hover { background-color: #f6f6f6; }
.bgcf6_i, .bgcf6_h_i:hover { background-color: #f6f6f6 !important; }
.bgcea, .bgcea_h:hover { background-color: #eaeaea; }
.bgcea_i, .bgcea_h_i:hover { background-color: #eaeaea !important; }
.bgcc, .bgcc_h:hover { background-color: #ccc; }
.bgcc_i, .bgcc_h_i:hover { background-color: #ccc !important; }
.bgcred, .bgcred_h:hover { background-color: #c1272c; }
.bgcred_i, .bgcred_i_h:hover { background-color: #c1272c !important; }
.bgcblue, .bgcblue_h:hover { background-color: #0086db; }
.bgcblue_i, .bgcblue_h_i:hover { background-color: #0086db !important; }
.bgclblue, .bgclblue_h:hover { background-color: #2ac2e4; }
.bgclblue_i, .bgclblue_h_i:hover { background-color: #2ac2e4 !important; }

.c0, .c0_a, .c0_a *, .c0_h:hover, .c0_a_h:hover, .c0_a_h:hover * { color: #000; }
.c0_i, .c0_a_i, .c0_a_i *, .c0_h_i:hover, .c0_a_h_i:hover, .c0_a_h_i:hover * { color: #000 !important; }
.cw, .cw_a, .cw_a *, .cw_h:hover, .cw_a_h:hover, .cw_a_h:hover * { color: #fff; }
.cw_i, .cw_a_i, .cw_a_i *, .cw_h_i:hover, .cw_a_h_i:hover, .cw_a_h_i:hover * { color: #fff !important; }
.cf6, .cf6_h:hover { color: #f6f6f6; }
.cf6_i, .cf6_h_i:hover { color: #f6f6f6 !important; }
.cea, .cea_h:hover { color: #eaeaea; }
.cea_i, .cea_h_i:hover { color: #eaeaea !important; }
.cc, .cc_h:hover { color: #ccc; }
.cc_i, .cc_h_i:hover { color: #ccc !important; }
.c80, .c80_h:hover { color: #808080; }
.c80_i, .c80_h_i:hover { color: #808080 !important; }
.c50, .c50_h:hover { color: #505050; }
.c50_i, .c50_h_i:hover { color: #505050 !important; }
label .required { color: #c1272c; }
.cblue, .cblue_h:hover { color: #0086db; }
.cblue_i, .cblue_h_i:hover { color: #0086db !important; }
.clblue, .clblue_h:hover { color: #2ac2e4; }
.clblue_i, .clblue_h_i:hover { color: #2ac2e4 !important; }

.brct, .brct_h:hover { border-color: transparent; }
.brct_i, .brct_h_i:hover { border-color: transparent !important; }
.brc0, .brc0_h:hover { border-color: #000; }
.brc0_i, .brc0_h_i:hover { border-color: #000 !important; }
.brcw, .brcw_h:hover { border-color: #fff; }
.brcw_i, .brcw_h_i:hover { border-color: #fff !important; }
.brcf6, .brcf6_h:hover { border-color: #f6f6f6; }
.brcf6_i, .brcf6_h_i:hover { border-color: #f6f6f6 !important; }
.brcea, .brcea_h:hover { border-color: #eaeaea; }
.brcea_i, .brcea_h_i:hover { border-color: #eaeaea !important; }
.brcc, .brcc_h:hover { border-color: #ccc; }
.brcc_i, .brcc_h_i:hover { border-color: #ccc !important; }
.brc80, .brc80_h:hover { border-color: #808080; }
.brc80_i, .brc80_h_i:hover { border-color: #808080 !important; }
.brc50, .brc50_h:hover { border-color: #505050; }
.brc50_i, .brc50_h_i:hover { border-color: #505050 !important; }
.brcblue, .brcblue_h:hover { border-color: #0086db; }
.brcblue_i, .brcblue_h_i:hover { border-color: #0086db !important; }
.brclblue, .brclblue_h:hover { border-color: #2ac2e4; }
.brclblue_i, .brclblue_h_i:hover { border-color: #2ac2e4 !important; }

.brw1 { border-width: 1px; }
.brtw1 { border-top-width: 1px; }
.brlw1 { border-left-width: 1px; }
.brrw1 { border-right-width: 1px; }
.brbw1 { border-bottom-width: 1px; }
.brw2 { border-width: 2px; }
.brtw2 { border-top-width: 2px; }
.brlw2 { border-left-width: 2px; }
.brrw2 { border-right-width: 2px; }
.brbw2 { border-bottom-width: 2px; }
.brw3 { border-width: 3px; }
.brtw3 { border-top-width: 3px; }
.brlw3 { border-left-width: 3px; }
.brrw3 { border-right-width: 3px; }
.brbw3 { border-bottom-width: 3px; }
.brw5 { border-width: 5px; }
.brtw5 { border-top-width: 5px; }
.brlw5 { border-left-width: 5px; }
.brrw5 { border-right-width: 5px; }
.brbw5 { border-bottom-width: 5px; }
.brw7 { border-width: 7px; }
.brtw7 { border-top-width: 7px; }
.brlw7 { border-left-width: 7px; }
.brrw7 { border-right-width: 7px; }
.brbw7 { border-bottom-width: 7px; }
.brw10 { border-width: 10px; }
.brtw10 { border-top-width: 10px; }
.brlw10 { border-left-width: 10px; }
.brrw10 { border-right-width: 10px; }
.brbw10 { border-bottom-width: 10px; }

.brstn { border-style: none; }
.brsts { border-style: solid; }
.brstd { border-style: dashed; }

.brs2 { border-radius: 2px; }
.brst2 { border-radius: 2px 2px 0px 0px; }
.brsl2 { border-radius: 2px 0px 0px 2px; }
.brsr2 { border-radius: 0px 2px 2px 0px; }
.brsb2 { border-radius: 0px 0px 2px 2px; }
.brs3 { border-radius: 3px; }
.brst3 { border-radius: 3px 3px 0px 0px; }
.brsl3 { border-radius: 3px 0px 0px 3px; }
.brsr3 { border-radius: 0px 3px 3px 0px; }
.brsb3 { border-radius: 0px 0px 3px 3px; }
.brs4 { border-radius: 4px; }
.brst4 { border-radius: 4px 4px 0px 0px; }
.brsl4 { border-radius: 4px 0px 0px 4px; }
.brsr4 { border-radius: 0px 4px 4px 0px; }
.brsb4 { border-radius: 0px 0px 4px 4px; }
.brs5 { border-radius: 5px; }
.brst5 { border-radius: 5px 5px 0px 0px; }
.brsl5 { border-radius: 5px 0px 0px 5px; }
.brsr5 { border-radius: 0px 5px 5px 0px; }
.brsb5 { border-radius: 0px 0px 5px 5px; }
.brs7 { border-radius: 7px; }
.brst7 { border-radius: 7px 7px 0px 0px; }
.brsl7 { border-radius: 7px 0px 0px 7px; }
.brsr7 { border-radius: 0px 7px 7px 0px; }
.brsb7 { border-radius: 0px 0px 7px 7px; }
.brs10 { border-radius: 10px; }
.brst10 { border-radius: 10px 10px 0px 0px; }
.brsl10 { border-radius: 10px 0px 0px 10px; }
.brsr10 { border-radius: 0px 10px 10px 0px; }
.brsb10 { border-radius: 0px 0px 10px 10px; }
.brs50p { border-radius: 50%; }

.dn { display: none; }
.dn_i { display: none !important; }
.db { display: block; }
.dib { display: inline-block; }
.dt { display: table; }
.dtr { display: table-row; }
.dtc { display: table-cell; }

.curp, .curp_a, .curp_a * { cursor: pointer; }
.curp_i, .curp_a_i, .curp_a_i * { cursor: pointer !important; }
.curd, .curd_a, .curd_a * { cursor: default; }
.curd_i, .curd_a_i, .curd_a_i * { cursor: default !important; }

.h0 { height: 0px; }
.hfull { height: 100%; }
.hauto { height: auto; }

.w0 { width: 0px; }
.w1 { width: 1px; }
.w200 { width: 200px; }
.w230 { width: 230px; }
.w260 { width: 260px; }
.w280 { width: 280px; }
.w320 { width: 320px; }
.w350 { width: 350px; }
.w400 { width: 400px; }
.w480 { width: 480px; }
.w570 { width: 570px; }
.w640 { width: 640px; }
.w700 { width: 700px; }
.w750 { width: 750px; }
.w800 { width: 800px; }
.w860 { width: 860px; }
.w960 { width: 960px; }
.w1024 { width: 1024px; }
.w1048 { width: 1048px; }
.w1100 { width: 1100px; }
.w1200 { width: 1200px; }
.w10p { width: 10%; }
.w12p { width: 12.5%; }
.w15p { width: 15%; }
.w20p { width: 20%; }
.w25p { width: 25%; }
.w33p { width: 33.33%; }
.w50p { width: 50%; }
.w65p { width: 65%; }
.w70p { width: 70%; }
.w75p { width: 75%; }
.wfull { width: 100%; }

.mar0a { margin: 0 auto; }
.m0 { margin: 0px; }
.m0_i { margin: 0px !important; }
.mt0 { margin-top: 0px; }
.mt0_i { margin-top: 0px !important; }
.ml0 { margin-left: 0px; }
.ml0_i { margin-left: 0px !important; }
.mr0 { margin-right: 0px; }
.mr0_i { margin-right: 0px !important; }
.mb0 { margin-bottom: 0px; }
.mb0_i { margin-bottom: 0px !important; }
.m1 { margin: 1px; }
.mt1 { margin-top: 1px; }
.ml1 { margin-left: 1px; }
.mr1 { margin-right: 1px; }
.mb1 { margin-bottom: 1px; }
.m2 { margin: 2px; }
.mt2 { margin-top: 2px; }
.ml2 { margin-left: 2px; }
.mr2 { margin-right: 2px; }
.mb2 { margin-bottom: 2px; }
.m3 { margin: 3px; }
.mt3 { margin-top: 3px; }
.ml3 { margin-left: 3px; }
.mr3 { margin-right: 3px; }
.mb3 { margin-bottom: 3px; }
.m4 { margin: 4px; }
.mt4 { margin-top: 4px; }
.ml4 { margin-left: 4px; }
.mr4 { margin-right: 4px; }
.mb4 { margin-bottom: 4px; }
.m5 { margin: 5px; }
.mt5 { margin-top: 5px; }
.ml5 { margin-left: 5px; }
.mr5 { margin-right: 5px; }
.mb5 { margin-bottom: 5px; }
.m7 { margin: 7px; }
.mt7 { margin-top: 7px; }
.ml7 { margin-left: 7px; }
.mr7 { margin-right: 7px; }
.mb7 { margin-bottom: 7px; }
.m10 { margin: 10px; }
.mt10 { margin-top: 10px; }
.ml10 { margin-left: 10px; }
.mr10 { margin-right: 10px; }
.mb10 { margin-bottom: 10px; }
.m13 { margin: 13px; }
.mt13 { margin-top: 13px; }
.ml13 { margin-left: 13px; }
.mr13 { margin-right: 13px; }
.mb13 { margin-bottom: 13px; }
.m15 { margin: 15px; }
.mt15 { margin-top: 15px; }
.ml15 { margin-left: 15px; }
.mr15 { margin-right: 15px; }
.mb15 { margin-bottom: 15px; }
.m20 { margin: 20px; }
.mt20 { margin-top: 20px; }
.ml20 { margin-left: 20px; }
.mr20 { margin-right: 20px; }
.mb20 { margin-bottom: 20px; }
.m-1 { margin: -1px; }
.mt-1 { margin-top: -1px; }
.ml-1 { margin-left: -1px; }
.mr-1 { margin-right: -1px; }
.mb-1 { margin-bottom: -1px; }
.m-2 { margin: -2px; }
.mt-2 { margin-top: -2px; }
.ml-2 { margin-left: -2px; }
.mr-2 { margin-right: -2px; }
.mb-2 { margin-bottom: -2px; }
.m-3 { margin: -3px; }
.mt-3 { margin-top: -3px; }
.ml-3 { margin-left: -3px; }
.mr-3 { margin-right: -3px; }
.mb-3 { margin-bottom: -3px; }
.m-4 { margin: -4px; }
.mt-4 { margin-top: -4px; }
.ml-4 { margin-left: -4px; }
.mr-4 { margin-right: -4px; }
.mb-4 { margin-bottom: -4px; }
.m-5 { margin: -5px; }
.mt-5 { margin-top: -5px; }
.ml-5 { margin-left: -5px; }
.mr-5 { margin-right: -5px; }
.mb-5 { margin-bottom: -5px; }
.m-7 { margin: -7px; }
.mt-7 { margin-top: -7px; }
.ml-7 { margin-left: -7px; }
.mr-7 { margin-right: -7px; }
.mb-7 { margin-bottom: -7px; }
.m-10 { margin: -10px; }
.mt-10 { margin-top: -10px; }
.ml-10 { margin-left: -10px; }
.mr-10 { margin-right: -10px; }
.mb-10 { margin-bottom: -10px; }
.m-13 { margin: -13px; }
.mt-13 { margin-top: -13px; }
.ml-13 { margin-left: -13px; }
.mr-13 { margin-right: -13px; }
.mb-13 { margin-bottom: -13px; }
.m-15 { margin: -15px; }
.mt-15 { margin-top: -15px; }
.ml-15 { margin-left: -15px; }
.mr-15 { margin-right: -15px; }
.mb-15 { margin-bottom: -15px; }
.m-20 { margin: -20px; }
.mt-20 { margin-top: -20px; }
.ml-20 { margin-left: -20px; }
.mr-20 { margin-right: -20px; }
.mb-20 { margin-bottom: -20px; }

.p0 { padding: 0px; }
.pt0 { padding-top: 0px; }
.pl0 { padding-left: 0px; }
.pr0 { padding-right: 0px; }
.pb0 { padding-bottom: 0px; }
.p1 { padding: 1px; }
.pt1 { padding-top: 1px; }
.pl1 { padding-left: 1px; }
.pr1 { padding-right: 1px; }
.pb1 { padding-bottom: 1px; }
.p2 { padding: 2px; }
.pt2 { padding-top: 2px; }
.pl2 { padding-left: 2px; }
.pr2 { padding-right: 2px; }
.pb2 { padding-bottom: 2px; }
.p3 { padding: 3px; }
.pt3 { padding-top: 3px; }
.pl3 { padding-left: 3px; }
.pr3 { padding-right: 3px; }
.pb3 { padding-bottom: 3px; }
.p4 { padding: 4px; }
.pt4 { padding-top: 4px; }
.pl4 { padding-left: 4px; }
.pr4 { padding-right: 4px; }
.pb4 { padding-bottom: 4px; }
.p5 { padding: 5px; }
.pt5 { padding-top: 5px; }
.pl5 { padding-left: 5px; }
.pr5 { padding-right: 5px; }
.pb5 { padding-bottom: 5px; }
.p7 { padding: 7px; }
.pt7 { padding-top: 7px; }
.pl7 { padding-left: 7px; }
.pr7 { padding-right: 7px; }
.pb7 { padding-bottom: 7px; }
.p10 { padding: 10px; }
.pt10 { padding-top: 10px; }
.pl10 { padding-left: 10px; }
.pr10 { padding-right: 10px; }
.pb10 { padding-bottom: 10px; }
.p13 { padding: 13px; }
.pt13 { padding-top: 13px; }
.pl13 { padding-left: 13px; }
.pr13 { padding-right: 13px; }
.pb13 { padding-bottom: 13px; }
.p15 { padding: 15px; }
.pt15 { padding-top: 15px; }
.pl15 { padding-left: 15px; }
.pr15 { padding-right: 15px; }
.pb15 { padding-bottom: 15px; }
.p20 { padding: 20px; }
.pt20 { padding-top: 20px; }
.pl20 { padding-left: 20px; }
.pr20 { padding-right: 20px; }
.pb20 { padding-bottom: 20px; }

.semilight, .semilight * { font-weight: 300; }
.semibold, .semibold * { font-weight: 500; }
.bold, .bold * { font-weight: 700; }

.tal { text-align: left; }
.tac { text-align: center; }
.tar { text-align: right; }

.tdn, .tdn_a, .tdn_a *, .tdn_h:hover, .tdn_a_h:hover, .tdn_a_h:hover * { text-decoration: none; }
.tdn_i, .tdn_a_i, .tdn_a_i *, .tdn_h_i:hover, .tdn_a_h_i:hover, .tdn_a_h_i:hover * { text-decoration: none !important; }
.tdu, .tdu_a, .tdu_a *, .tdu_h:hover, .tdu_a_h:hover, .tdu_a_h:hover * { text-decoration: underline; }
.tdu_i, .tdu_a_i, .tdu_a_i *, .tdu_h_i:hover, .tdu_a_h_i:hover, .tdu_a_h_i:hover * { text-decoration: underline !important; }
.tdlth, .tdlth_a, .tdlth_a *, .tdlth_h:hover, .tdlth_a_h:hover, .tdlth_a_h:hover * { text-decoration: line-through; }
.tdlth_i, .tdlth_a_i, .tdlth_a_i *, .tdlth_h_i:hover, .tdlth_a_h_i:hover, .tdlth_a_h_i:hover * { text-decoration: line-through !important; }

.vat { vertical-align: top; }
.vam { vertical-align: middle; }
.vab { vertical-align: bottom; }
.vabl { vertical-align: baseline; }

.fz0 { font-size: 0; }
.fz0_i { font-size: 0 !important; }
.fz10, .fz10_a, .fz10_a * { font-size: 10px; }
.fz11, .fz11_a, .fz11_a * { font-size: 11px; }
.fz12, .fz12_a, .fz12_a * { font-size: 12px; }
.fz14, .fz14_a, .fz14_a * { font-size: 14px; }
.fz16 { font-size: 16px; }
.fz18 { font-size: 18px; }
.fz20 { font-size: 20px; }
.fz22 { font-size: 22px; }
.fz24 { font-size: 24px; }
.fz26 { font-size: 26px; }
.fz28 { font-size: 28px; }
.fz30 { font-size: 30px; }
.fz32 { font-size: 32px; }
.fz34 { font-size: 34px; }
.fz36 { font-size: 36px; }
.fz52 { font-size: 52px; }
.fz54 { font-size: 54px; }
.fsi { font-style: italic; }

.z0 { z-index: 0; }
.z4 { z-index: 4; }
.z9 { z-index: 9; }
.z99 { z-index: 99; }
.z999 { z-index: 999; }
.z9999 { z-index: 9999; }

.caps { text-transform: uppercase; }

.ofh { overflow: hidden; }

.inherit { font-family: inherit; font-size: inherit; font-weight: inherit; font-style: inherit; text-transform: inherit; }
.fzinherit { font-size: inherit; }

.posr { position: relative; }
.posa { position: absolute; }
.posf { position: fixed; }

.t0 { top: 0px; }
.t100p { top: 100%; }
.t-100p { top: -100%; }
.l0 { left: 0px; }
.l100p { left: 100%; }
.l-100p { left: -100%; }
.r0 { right: 0px; }
.r100p { right: 100%; }
.r-100p { right: -100%; }
.b0 { bottom: 0px; }
.b100p { bottom: 100%; }
.b-100p { bottom: -100%; }

.fll { float: left; }
.flr { float: right; }

.with_top_inset_shadow {
  box-shadow: inset 0px 1px 0px 0px rgba(0,0,0,.1);
}

.arrow {
  content: "";
  display: inline-block;
  font-size: 0px;
  height: 16px;
  width: 16px;
  position: relative;
  border-style: solid;
  border-color: #111;

  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
  .arrow:before {
    content: "";
    display: block;
    font-size: 0px;
    height: 0px;
    width: 20px;
    position: absolute;
    top: 6px;
    left: -3px;
    border-bottom: 2px solid #111;

    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .arrow.larrow {
    border-top-width: 2px;
    border-left-width: 2px;
  }
  .arrow.rarrow {
    border-right-width: 2px;
    border-bottom-width: 2px;
  }
  .arrow.small {
    width: 10px;
    height: 10px;
  }
  .arrow.small:before {
    width: 12px;
    top: 3px;
    left: -2px;
  }
  .arrow.cw, .arrow.cw:before {
    border-color: #fff;
  }
  .arrow.cblue, .arrow.cblue:before {
    border-color: #0086db;
  }
  .arrow.clblue, .arrow.clblue:before {
    border-color: #2ac2e4;
  }

p { margin-bottom: 13px; }

a.link[href] {
  color: #0086db;
  text-decoration: underline;
  cursor: pointer;
}
  a.link[href]:hover {
    text-decoration: none;
  }

input, textarea, button {
  overflow: hidden;
  resize: none;
}

.errorMessage {
  padding: 7px 7px 6px;
  margin-top: -3px;
  color: #fff;
  font-size: 12px;
}

ul { list-style: none outside; }
ol { list-style: decimal inside; }
  ul.ul, ul.ul2, ol.ol { padding-left: 13px; margin-bottom: 10px; }
    ul.ul li, ul.ul2 li, ol.ol li {
      padding-top: 0px;
      padding-bottom: 3px;
      position: relative;
    }
    ul.ul li, ul.ul2 li {
      padding-left: 24px;
    }
      ul.ul ul.ul li,
      ul.ul ul.ul2 li,
      ul.ul2 ul.ul li,
      ul.ul2 ul.ul2 li {
        padding-left: 20px;
      }
      ul.ul li:before,
      ul.ul2 ul.ul li:before {
        content: "";
        background: url('/i/ul-mark.png') 50% 50% no-repeat transparent;
        position: absolute;
        width: 16px;
        height: 16px;
        left: 0px;
        top: 3px;
      }
      ul.ul2 li:before {
        content: "";
        background: #0086db;
        position: absolute;
        width: 7px;
        height: 7px;
        left: 0px;
        top: 7px;
      }

.cycle-prev, .cycle-next {
  width: 30px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAYAAAASYli2AAAAdklEQVR42mNgoAyIAHEWEKcCMT+FZjGoAvFDIP4PxXcpMRRk2GMkw2A4nZqGkWUgPsMekuplfIY9gcqPGjZAhj0eNWyIGQYz8AkeA1XILUGGh6Gqo4ZStf4YOoY+GTKGklyN0qSip0lThCaNJeTmXDYQp8EMAwDqHspUnd6TSAAAAABJRU5ErkJggg==');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  box-sizing: content-box;
  opacity: 0.7;

  -webkit-transition: opacity 0.2s ease-out 0s;
  -moz-transition: opacity 0.2s ease-out 0s;
  -o-transition: opacity 0.2s ease-out 0s;
  transition: opacity 0.2s ease-out 0s;
}
  .cycle-next {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAYAAAASYli2AAAAa0lEQVR42mNggAARIM4C4lQg5megEIAMuA/E/6EYxFalxMBUJMNg+DElhqZhMZAiQ0FefkhtQ1WhmkcNHTV01FA0Q1UGhaEq1PS66qhho4aNaMOoXo2mUrtYSqN2U4TqjSVYcy4b6lqKmnMAUsTKQAbA56kAAAAASUVORK5CYII=');
  }
  .cycle-prev:hover,
  .cycle-next:hover {
    opacity: 1;
  }

.image-gallery {
  font-size: 0px;
  position: relative;
  margin-bottom: 20px;
}
  .image-gallery .image-gallery-items {
    margin: -16px 0px 0px -16px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: stretch;
  }
    .image-gallery .image-gallery-item {
      font-size: 0px;
      padding: 16px 0px 0px 16px;
      margin: auto;
      position: relative;
    }
  .image-gallery .image-gallery-notice {
    width: 100%;
    padding: 0px 0px 0px 16px;
    text-align: center;
  }

a.fancybox-img:before {
  content: "";
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmlld0JveD0iMCAwIDMyMCAzMjAiPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9IiNmZmYiIHJ4PSIxNSUiLz48cGF0aCBmaWxsPSIjMDA4NmRiIiBkPSJNMjQ2LjYsMjc3LjdjLTguOC04LjgtMzcuMi0zNy4yLTUzLjUtNTMuNWMtMTYsMTAuMi0zNC45LDE2LjItNTUuMywxNi4yYy01Ni43LDAtMTAyLjYtNDYtMTAyLjYtMTAyLjYgQzM1LjEsODEsODEsMzUuMSwxMzcuNywzNS4xYzU2LjcsMCwxMDIuNiw0NiwxMDIuNiwxMDIuNmMwLDIwLjQtNiwzOS40LTE2LjIsNTUuM2MxNi4zLDE2LjMsNDQuOCw0NC44LDUzLjUsNTMuNSBDMjk5LjUsMjY4LjQsMjY4LjQsMjk5LjUsMjQ2LjYsMjc3Ljd6IE0xMzcuNyw2Ni4yYy0zOS41LDAtNzEuNSwzMi03MS41LDcxLjVjMCwzOS41LDMyLDcxLjUsNzEuNSw3MS41YzM5LjUsMCw3MS41LTMyLDcxLjUtNzEuNSBDMjA5LjMsOTguMiwxNzcuMiw2Ni4yLDEzNy43LDY2LjJ6IE0xMzYuMyw5My40Yy0yMC4xLDAtMzcuOSwxMC4zLTQ4LjIsMjZjNy41LTIwLjIsMjYuOS0zNC41LDQ5LjYtMzQuNSBjMjYuNywwLDQ4LjcsMTkuNyw1Mi4zLDQ1LjRDMTgxLjcsMTA4LjcsMTYwLjgsOTMuNCwxMzYuMyw5My40eiIvPjwvc3ZnPg==') 50% 50% no-repeat transparent;
  background-size: contain;
  position: absolute;
  top: 43%;
  left: 43%;
  width: 14%;
  height: 14%;
  opacity: 0;

  -webkit-transition: top 0.2s ease-in-out 0s, left 0.2s ease-in-out 0s, width 0.2s ease-in-out 0s, height 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
  -moz-transition: top 0.2s ease-in-out 0s, left 0.2s ease-in-out 0s, width 0.2s ease-in-out 0s, height 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
  -o-transition: top 0.2s ease-in-out 0s, left 0.2s ease-in-out 0s, width 0.2s ease-in-out 0s, height 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
  transition: top 0.2s ease-in-out 0s, left 0.2s ease-in-out 0s, width 0.2s ease-in-out 0s, height 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
}
  a.fancybox-img:hover:before {
    top: 30%;
    left: 30%;
    width: 40%;
    height: 40%;
    opacity: 0.8;
  }


.wrapper {
  width: 1100px;
  position: relative;
  margin: 0px auto;
  padding: 0px;
}


/*.main-cycle-slideshow-container .gallery-text {
  position: absolute;
  top: 0px;
  right: 10px;
  width: 310px;
  height: 60px;
  z-index: 5;
}
.main-cycle-slideshow-container .gallery-controls {
  position: absolute;
  bottom: 30px;
  left: 130px;
  z-index: 5;
}
  .main-cycle-slideshow-container .gallery-controls .cycle-prev,
  .main-cycle-slideshow-container .gallery-controls .cycle-next {
    display: inline-block;
    width: 45px;
    cursor: pointer;

    height: 24px;
    margin-top: 0px;

    -webkit-transition: height 0.06s ease-out 0s, margin-top 0.06s ease-out 0s;
    -moz-transition: height 0.06s ease-out 0s, margin-top 0.06s ease-out 0s;
    -o-transition: height 0.06s ease-out 0s, margin-top 0.06s ease-out 0s;
    transition: height 0.06s ease-out 0s, margin-top 0.06s ease-out 0s;
  }
    .main-cycle-slideshow-container .gallery-controls .cycle-prev:active,
    .main-cycle-slideshow-container .gallery-controls .cycle-next:active {
      height: 20px;
      margin-top: 2px;
    }
.main-cycle-slideshow-container .cycle-slideshow {
  height: 185px;
  z-index: 1;
}
  .main-cycle-slideshow-container .cycle-slideshow .slide {
    display: inline-block;
    position: relative !important;
    margin-left: 15px;

    width: 135px;
    padding: 0px;
    margin-top: 35px;
    z-index: 1 !important;


    -webkit-transition: width 0.5s ease-out 0s, padding 0.5s ease-out 0s, margin-top 0.5s ease-out 0s;
    -moz-transition: width 0.5s ease-out 0s, padding 0.5s ease-out 0s, margin-top 0.5s ease-out 0s;
    -o-transition: width 0.5s ease-out 0s, padding 0.5s ease-out 0s, margin-top 0.5s ease-out 0s;
    transition: width 0.5s ease-out 0s, padding 0.5s ease-out 0s, margin-top 0.5s ease-out 0s;
  }
    .main-cycle-slideshow-container .cycle-slideshow .slide:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      z-index: 0;
      border-radius: 4px;
      box-shadow: inset 0px 1px 0px 0px rgba(0,0,0,.1);
      background: #dcd8d2;

      opacity: 0;

      -webkit-transition: opacity 0.5s ease-out 0s;
      -moz-transition: opacity 0.5s ease-out 0s;
      -o-transition: opacity 0.5s ease-out 0s;
      transition: opacity 0.5s ease-out 0s;
    }
    .main-cycle-slideshow-container .cycle-slideshow .slide:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 10%;
      left: 14px;
      bottom: -3px;
      padding: 10px;
      border-radius: 20px;
      z-index: -1;

      background: rgba(0,0,0,.2);
      -webkit-transform: skewX(-50deg);
      -moz-transform: skewX(-50deg);
      -o-transform: skewX(-50deg);
      transform: skewX(-50deg);
      -webkit-filter: blur(5px);
      -ms-filter: blur(5px);
      filter: blur(5px);

      opacity: 0;

      -webkit-transition: opacity 0.5s ease-out 0s;
      -moz-transition: opacity 0.5s ease-out 0s;
      -o-transition: opacity 0.5s ease-out 0s;
      transition: opacity 0.5s ease-out 0s;
    }
    .main-cycle-slideshow-container .cycle-slideshow .slide img  {
      border-radius: 0px;

      -webkit-transition: border-radius 0.5s ease-out 0s;
      -moz-transition: border-radius 0.5s ease-out 0s;
      -o-transition: border-radius 0.5s ease-out 0s;
      transition: border-radius 0.5s ease-out 0s;
    }
    .main-cycle-slideshow-container .cycle-slideshow .slide.cycle-slide-active {
      width: 274px;
      padding: 5px;
      margin-top: 0px;
      z-index: 0 !important;
    }
      .main-cycle-slideshow-container .cycle-slideshow .slide.cycle-slide-active ~ .slide {
        margin-top: 65px;
      }
      .main-cycle-slideshow-container .cycle-slideshow .slide.cycle-slide-active img  {
        border-radius: 3px;
      }
        .main-cycle-slideshow-container .cycle-slideshow .slide.cycle-slide-active:before,
        .main-cycle-slideshow-container .cycle-slideshow .slide.cycle-slide-active:after {
          opacity: 1;
        }*/

.price-table {
  width: 100%;
  border-bottom: 4px solid #d8e3f1;
  margin-bottom: 20px;
}
  .price-table th {
    background: #0086db;
    text-align: center;
    font-size: 17px;
    color: #fff;
    padding: 10px 5px;
    vertical-align: middle;
  }
  .price-table tr td {
    background: #d8e3f1;
    padding: 0px 6px;
    border-top: 4px solid #d8e3f1;
    border-left: 4px solid #d8e3f1;
    border-right: 4px solid #d8e3f1;
    vertical-align: middle;
  }
    .price-table tr td + td {
      text-align: center;
      border-left: none;
    }
    .price-table tr td.price-unit {
      width: 15%;
    }
    .price-table tr td.price-cost {
      width: 18%;
    }
    .price-table tr.price-subheader td {
      text-align: center;
      background-color: rgba(255,255,255,.7) !important;
      padding: 6px 0px !important;
      font-weight: 500;
      font-size: 15px;
    }
      .price-table tr.price-subheader ~ tr:nth-child(2n) td {
        background: #f6fafd;
        padding: 3px 6px;
      }
      .price-table tr.price-subheader td span {
        font-weight: 500;
        font-size: 23px;
      }
    .price-table tr.delimiter td {
      background-color: transparent !important;
      padding: 20px 0px !important;
      border-left: none;
      border-right: none;
    }

/*.list-view.list-view-loading:before {
  content: "";
  background: url('/i/list-view-loading.gif') no-repeat top 50% left 50% rgba(255,255,255,.75);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}*/

.go-top-button-container {
  display: inline-block;
  width: 100%;
  height: 0px;
  text-align: center;
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: 99;
}
  #go-top-button {
    background: #0086db;
    border-radius: 3px 3px 0px 0px;
    width: 32px;
    height: 32px;
    padding: 8px;
    margin-top: 2px;
    margin-right: -48px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    box-sizing: content-box;
    opacity: 0.8;

    -webkit-transition: opacity 0.2s ease-out 0s, margin-top 0.2s ease-out 0s;
    -moz-transition: opacity 0.2s ease-out 0s, margin-top 0.2s ease-out 0s;
    -o-transition: opacity 0.2s ease-out 0s, margin-top 0.2s ease-out 0s;
    transition: opacity 0.2s ease-out 0s, margin-top 0.2s ease-out 0s;
  }
    #go-top-button:hover {
      opacity: 1;
    }
    #go-top-button.go-top-button-custom-visible {
      -webkit-transition: opacity 0.2s ease-out 0s, margin-top 0s ease-out 0s;
      -moz-transition: opacity 0.2s ease-out 0s, margin-top 0s ease-out 0s;
      -o-transition: opacity 0.2s ease-out 0s, margin-top 0s ease-out 0s;
      transition: opacity 0.2s ease-out 0s, margin-top 0s ease-out 0s;
    }
    #go-top-button.top-button-custom-visible,
    #go-top-button.top-button-visible {
      margin-top: -48px;
    }
    #go-top-button > div {
      width: 100%;
      height: 100%;
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAUCAYAAADskT9PAAAApUlEQVR42sWVbQqAIAxAvYF/OkI7Wv6oQ4d4EHOxQCz83GwwCBx7L+aHUgPhvV9DgvojCO4oYTYcCPzEPIkP+DyJDFxeogIuJ9EA55cowF1hDaThEB1HXokaeE8tO5xdYqTRsATHX3T34Jxjcy/2TdTSM3zokKfEWS5IIFNj0SZ5mxUkDBbs0vd5RuLAxSUZgZV40UjCJiNY4n2AozD3XOSe8xfnAtDF5U8f0h3tAAAAAElFTkSuQmCC') no-repeat 50% 50% transparent;
      background-size: contain;
    }

.discount-button-container {
  display: inline-block;
  width: 100%;
  height: 0px;
  text-align: right;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 95;
}
  .discount-button {
    display: inline-block;
    background: #0086db;
    border-radius: 3px 3px 0px 0px;
    top: 360px;
    right: -28px;
    min-width: 90px;
    padding: 7px 10px 8px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    position: relative;
    cursor: pointer;
    color: #fff;

    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

.header {
  background: #000;
  position: relative;
  z-index: 5;
}
  .header-discounts-rotator {
    border-bottom: 1px solid #fff;
    padding: 5px 0px;
    margin: 0px 0px 5px;
    position: relative;
  }
    .header-discounts-rotator > div {
      height: 21px;
      position: relative;
      overflow: hidden;
    }
      .header-discounts-rotator .cycle-carousel-wrap {
        right: 0px;
      }

      .header-discounts-rotator .slide {
        color: #fff;
        text-align: center;
      }
        .header-discounts-rotator .slide .link {
          color: #fff;
        }

  .header-info-menu-container {
    text-align: right;
  }
    .header-info-menu {
      display: inline-block;
    }
      .header-info-menu ul {
        display: table;
        padding: 8px 0px;
      }
        .header-info-menu ul li {
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          font-size: 0;
        }
          .header-info-menu ul li + li {
            border-left: 1px solid #2ac2e4;
          }
          .header-info-menu ul li a {
            font-size: 0;
          }
            .header-info-menu ul li span {
              display: inline-block;
              cursor: pointer;
              font-size: 15px;
              color: #fff;
              padding: 0px 6px 1px;
              line-height: 110%;
              font-weight: 300;

              -webkit-transition: color 0.3s ease 0s;
              -moz-transition: color 0.3s ease 0s;
              -o-transition: color 0.3s ease 0s;
              transition: color 0.3s ease 0s;
            }
              .header-info-menu ul li:hover span,
              .header-info-menu ul li.active span {
                color: #2ac2e4;
              }
              .header-info-menu ul li.active span {
                cursor: default;
                font-weight: 500;
              }

  .header-section-menu > ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background: #0086db;
  }
    .header-section-menu > ul > li {
      width: 100%;
      position: relative;
      padding: 8px 0px;

      -webkit-transition: background-color 0.2s ease 0s;
      -moz-transition: background-color 0.2s ease 0s;
      -o-transition: background-color 0.2s ease 0s;
      transition: background-color 0.2s ease 0s;
    }
      .header-section-menu > ul > li:hover,
      .header-section-menu > ul > li.is-hovered,
      .header-section-menu > ul > li.active {
        background: #2ac2e4;
      }
      .header-section-menu > ul > li > a span {
        display: block;
        cursor: pointer;
        text-align: center;
        font-size: 16px;
        /*text-transform: uppercase;*/
        padding: 0px 0px 1px;
        color: #fff;
      }
        .header-section-menu > ul > li + li > a span {
          border-left: 1px solid #fff;

          -webkit-transition: border-color 0.2s ease 0s;
          -moz-transition: border-color 0.2s ease 0s;
          -o-transition: border-color 0.2s ease 0s;
          transition: border-color 0.2s ease 0s;
        }
        .header-section-menu > ul > li:hover > a span,
        .header-section-menu > ul > li:hover + li > a span,
        .header-section-menu > ul > li.is-hovered > a span,
        .header-section-menu > ul > li.is-hovered + li > a span,
        .header-section-menu > ul > li.active > a span,
        .header-section-menu > ul > li.active + li > a span {
          border-color: transparent;
        }
        .header-section-menu > ul > li.active > a span {
          cursor: default;
          font-weight: 700;
        }
      .header-section-menu > ul > li > ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        position: absolute;
        top: 100%;
        left: 0px;
        padding: 15px 18px;
        background: #fff;
        min-width: 100%;
        border: 1px solid #dee6ec;
        border-top: none !important;

        visibility: hidden;
        opacity: 0;

        -webkit-transition: visibility 0s ease 0.2s, opacity 0.2s ease 0s;
        -moz-transition: visibility 0s ease 0.2s, opacity 0.2s ease 0s;
        -o-transition: visibility 0s ease 0.2s, opacity 0.2s ease 0s;
        transition: visibility 0s ease 0.2s, opacity 0.2s ease 0s;
      }
        /*.header-section-menu > ul > li:nth-last-child(3) > ul,*/
        .header-section-menu > ul > li:nth-last-child(2) > ul,
        .header-section-menu > ul > li:nth-last-child(1) > ul {
          left: auto;
          right: 0px;
        }
        .header-section-menu > ul > li:hover > ul,
        .header-section-menu > ul > li.is-hovered > ul {
          visibility: visible;
          opacity: 1;

          -webkit-transition: visibility 0s ease 0s, opacity 0.2s ease 0s;
          -moz-transition: visibility 0s ease 0s, opacity 0.2s ease 0s;
          -o-transition: visibility 0s ease 0s, opacity 0.2s ease 0s;
          transition: visibility 0s ease 0s, opacity 0.2s ease 0s;
        }
        /*.header-section-menu > ul > li > ul > li {
          display: table-cell;
          padding: 0px 18px;
        }*/
        .header-section-menu > ul > li > ul > li + li {
          padding-left: 80px;
        }
          .header-section-menu > ul > li > ul > li > span,
          .header-section-menu > ul > li > ul > li > a span {
            display: block;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 500;
            padding-bottom: 7px;
            white-space: nowrap;
          }
            .header-section-menu > ul > li:nth-child(2) > ul > li:first-child > ul {
              display: flex;
              flex-flow: column wrap;
              min-width: 250px;
              max-height: 115px;
            }
              .header-section-menu > ul > li:nth-child(2) > ul > li:first-child > ul > li:nth-child(n+4) ~ li {
                margin-left: 25px;
              }
                .header-section-menu > ul > li > ul > li > ul > li > a span {
                  white-space: nowrap;
                  display: block;
                  padding: 2px 15px 2px 0px;

                  -webkit-transition: color 0.3s ease 0s;
                  -moz-transition: color 0.3s ease 0s;
                  -o-transition: color 0.3s ease 0s;
                  transition: color 0.3s ease 0s;
                }
                  .header-section-menu > ul > li > ul > li > ul > li + li > a span {
                    border-top: 1px solid #dee6ec;
                  }
                    .header-section-menu > ul > li:nth-child(2) > ul > li:first-child > ul > li:nth-child(5) > a span {
                      border-top: none;
                    }
                  .header-section-menu > ul > li > ul > li > ul > li > a span:hover,
                  .header-section-menu > ul > li > ul > li > ul > li.is-hovered > a span {
                    color: #0086db;
                  }
                  .header-section-menu > ul > li > ul > li > ul > li.active > a span {
                    cursor: default;
                    font-weight: 500;
                    color: #0086db;
                  }

  .header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
  }
    .header-phone-wrapper {
      padding-bottom: 2px;
    }
      .header-phone {
        text-align: center;
      }
        .header-phone a {
          font-size: 28px;
          font-weight: 700;
          color: #fff;
          cursor: default;
        }
      .header-phone-description {
        text-align: center;
        color: #2ac2e4;
        font-size: 15px;
      }
    .header-callback-button a {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
      .header-callback-button a,
      .header-callback-button a * {
        cursor: pointer;
      }
      .header-callback-button a:before {
        content: "";
        width: 42px;
        align-self: stretch;
        background: url('/i/header-phone.png') no-repeat right center transparent;
        background-size: contain;
        margin-right: 10px;
      }
      .header-callback-button a div {
        color: #fff;
        text-transform: uppercase;
        font-size: 17px;
        line-height: 100%;

        -webkit-transition: color 0.2s ease 0s;
        -moz-transition: color 0.2s ease 0s;
        -o-transition: color 0.2s ease 0s;
        transition: color 0.2s ease 0s;
      }
        .header-callback-button a:hover div {
          color: #2ac2e4;
        }

    .header-socials {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      align-content: center;
      align-items: center;
      max-width: 30%;
      margin: -12px 0px 0px -12px;
    }
      .header-socials > div {
        padding: 12px 0px 0px 12px;
      }
        .header-socials > div > div {
          border-radius: 4px;
          overflow: hidden;
        }
          .header-socials a {
            display: block;
            background: url('/i/socials.svg') no-repeat 50% 50% #0086db;
            border: 10px solid #0086db;
            box-sizing: content-box;
            line-height: 0;
            width: 22px;
            min-width: 22px;
            height: 22px;
            cursor: pointer;
          }

          .header-socials a.icon-facebook  { background-color: #0866ff; border-color: #0866ff; background-position: 0 0; }
          .header-socials a.icon-telegram  { background-color: #2aabee; border-color: #2aabee; background-position: 0 -23px; }
          .header-socials a.icon-vk        { background-color: #07f; border-color: #07f; background-position: 0 -46px; }
          .header-socials a.icon-instagram { /*background-color: #2ac2e4; border-color: #2ac2e4;*/ background-position: -23px 0; }
          .header-socials a.icon-tiktok    { /*background-color: #2ac2e4; border-color: #2ac2e4;*/ background-position: -23px -23px; }
          .header-socials a.icon-whatsapp  { background-color: #46c756; border-color: #46c756; background-position: -23px -46px; }
          .header-socials a.icon-ok        { background-color: #f80; border-color: #f80; background-position: -46px 0; }
          .header-socials a.icon-twitter   { /*background-color: #2ac2e4; border-color: #2ac2e4;*/ background-position: -46px -23px; }
          .header-socials a.icon-youtube   { background-color: #f00; border-color: #f00; background-position: -46px -46px; }
          .header-socials a.icon-pinterest { background-color: #e60023; border-color: #e60023; background-position: -69px 0; }
          .header-socials a.icon-viber     { background-color: #7360f2; border-color: #7360f2; background-position: -69px -23px; }
          .header-socials a.icon-zen       { /*background-color: #2ac2e4; border-color: #2ac2e4;*/ background-position: -69px -46px; }


.content {
  display: inline-block;
  width: 100%;
  padding: 25px 0px;
  position: relative;
  z-index: 1;
}

.subheader {
  font-size: 22px;
  text-transform: uppercase;
  padding: 10px 0px;
  font-weight: 500;
}
  h1.subheader {
    font-size: 26px;
    padding-top: 0px;
  }


.anchor-link-container {
  float: left;
  padding: 0px 50px 15px 0px;
}
  .anchor-link {
    font-size: 0px;
  }
    .anchor-link + .anchor-link {
      padding-top: 10px;
    }
    .anchor-link a[href] {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
    .anchor-link a[href] * {
      cursor: pointer;
    }
      .anchor-arrow-container {
        padding: 4px;
        background: #f6f6f6;
        font-size: 0px;
      }
      .anchor-text {
        margin: 2px 0px 0px 15px;
        line-height: 110%;
        font-size: 12px;
        text-transform: uppercase;
        color: #0086db;
        border-bottom: 1px dashed rgba(54,108,219,.5);

        -webkit-transition: color 0.2s ease 0s, border-bottom-color 0.2s ease 0s;
        -moz-transition: color 0.2s ease 0s, border-bottom-color 0.2s ease 0s;
        -o-transition: color 0.2s ease 0s, border-bottom-color 0.2s ease 0s;
        transition: color 0.2s ease 0s, border-bottom-color 0.2s ease 0s;
      }
        .anchor-link a[href]:hover .anchor-text {
          border-bottom-color: transparent;
        }


.vitrine-container {
  margin: 0px 0px 0px -16px;
}
  .vitrine {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: stretch;
  }
  .vitrine .item {
    margin: 0px 0px 20px 16px;
    background: #f6f6f6;
  }
    .vitrine .width-2 {
      width: 542px;
    }
    .vitrine .width-3 {
      width: 356px;
    }
    .vitrine .width-4 {
      width: 263px;
    }
    .vitrine .item-image-name-container {
      position: relative;
    }
      .vitrine .item-image-container a,
      .vitrine .item-image-container a * {
        cursor: default;
      }
        .vitrine .item-image-container a[href],
        .vitrine .item-image-container a[href] * {
          cursor: pointer !important;
        }
        .vitrine .item-image-bkgd {
          background: url('/i/product-image-default.jpg') no-repeat 50% 50% transparent;
          background-size: cover;
        }
          .vitrine .width-2 .item-image-bkgd {
            height: 330px;
          }
          .vitrine .width-3 .item-image-bkgd {
            height: 220px;
          }
          .vitrine .width-4 .item-image-bkgd {
            height: 160px;
          }
      .vitrine .item-name-container {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
      }
        .vitrine .item-header {
          text-align: center;
          padding: 5px 0px 4px;
          background: rgba(255,255,255,.8);
        }
          .vitrine .item-header a {
            font-size: 19px;
            text-transform: uppercase;
            font-weight: 500;
            line-height: 130%;
            border-bottom: 1px solid transparent;
          }
          .vitrine .item-header a[href] {
            border-bottom-color: rgba(0,0,0,.3);
          }
            .vitrine .item-header a[href]:hover,
            .vitrine .item-image-container:hover + .item-name-container .item-header a[href]  {
              border-bottom-color: #111;
            }
    .vitrine .item .item-text {
      padding: 13px 13px 0px 13px;
      overflow: hidden;
    }
      .vitrine .item .item-text * {
        font-size: 14px;
      }

.product-categories {
  text-align: center;
  margin: -20px 0px 20px -20px;
}
  .product-categories .product-category {
    display: inline-block;
    width: 154px;
    position: relative;
    margin: 20px 0px 0px 20px;
  }
    .product-categories .product-category.with-notice, .product-categories .product-category.with-notice * {
      cursor: pointer;
    }
    .product-categories .product-category .product-category-image {
      text-align: center;
      padding-bottom: 5px;
    }
    .product-categories .product-category .product-category-name {
      text-align: center;
    }
      .product-categories .product-category .product-category-name span {
        color: #0086db;
      }
      .product-categories .product-category.with-notice .product-category-name span {
        border-bottom: 1px dashed rgba(0,134,219,.3);
      }
      .product-categories .product-category.with-notice:hover .product-category-name span,
      .product-categories .product-category.with-notice.is-hovered .product-category-name span {
        border-bottom-color: #0086db;
      }
    .product-categories .product-category .product-category-popup-text {
      display: none;
      background: rgba(0,0,0,.8);
      padding: 7px;
      color: #fff;
      font-size: 12px;
      position: absolute;
      width: 300px;
      left: 50%;
      bottom: 100%;
      margin-left: -150px;
      margin-bottom: 12px;
    }
      .product-categories .product-category.with-notice:hover .product-category-popup-text,
      .product-categories .product-category.with-notice.is-hovered .product-category-popup-text {
        display: block;
      }
      .product-categories .product-category .product-category-popup-text:before {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        bottom: 0px;
        height: 0px;
        width: 0px;
        margin-bottom: -12px;
        margin-left: -12px;
        border-top: 12px solid rgba(0,0,0,.8);
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
      }
      .product-categories .product-category.first .product-category-popup-text {
        margin-left: -77px;
      }
      .product-categories .product-category.last .product-category-popup-text {
        margin-left: -223px;
      }
        .product-categories .product-category.first .product-category-popup-text:before {
          margin-left: -85px;
        }
        .product-categories .product-category.last .product-category-popup-text:before {
          margin-left: 61px;
        }

.top-slideshow-container {
  height: 335px;
  width: 100%;
  display: table;
  padding-bottom: 25px;
}
  .top-slideshow-container > div {
    display: table-cell;
    height: 100%;
  }
  .top-slideshow-container .top-slideshow-rotator {
    width: 750px;
    padding: 5px;
    background-color: #f6f6f6;
    border-radius: 4px;
  }
    .top-slideshow-container .cycle-slideshow .slide {
      width: 100%;
      height: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-color: transparent;
      border-radius: 3px;
    }
  .top-slideshow-container .top-slideshow-mini {
    padding-left: 25px;
  }

.footer {
  position: relative;
  z-index: 4;
  background: #000;
  padding: 25px 0px;
}
  .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
    .footer-contact-container > div + div {
      padding-top: 18px;
    }
      .footer-phone {
        color: #fff;
        font-size: 24px;
        font-weight: 500;
        cursor: default;
      }
      .footer-email {
        color: #2ac2e4;
        font-size: 16px;
        cursor: pointer;
        text-decoration: underline;
      }
        .footer-email:hover  {
          text-decoration: none;
        }

    .footer-menu-container {
      align-self: flex-start;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      padding-top: 5px;
    }
      .footer-menu + .footer-menu {
        padding-left: 60px;
      }
        .footer-menu .footer-menu-title {
          color: #fff;
          font-weight: 700;
          text-transform: uppercase;
          padding-bottom: 5px;
        }
        .footer-menu ul li {
          font-size: 0px;
          margin-bottom: 4px;
        }
          .footer-menu ul li span {
            font-size: 13px;
            cursor: pointer;
            line-height: 130%;
            color: #fff;
            border-bottom: 1px solid rgba(255,255,255,.5);

            -webkit-transition: color 0.3s ease 0s, border-bottom-color 0.3s ease 0s;
            -moz-transition: color 0.3s ease 0s, border-bottom-color 0.3s ease 0s;
            -o-transition: color 0.3s ease 0s, border-bottom-color 0.3s ease 0s;
            transition: color 0.3s ease 0s, border-bottom-color 0.3s ease 0s;
          }
          .footer-menu ul li span:hover,
          .footer-menu ul li.active span {
            color: #2ac2e4;
            border-bottom-color: #2ac2e4;
          }
          .footer-menu ul li.active span {
            cursor: default;
            font-weight: 500;
          }

  .footer-copyrights {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 30px 0px 0px;
  }



/* Other */
.grecaptcha-badge {
  z-index: 9;
}